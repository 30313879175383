import React from 'react';
import { Asset } from 'contentful';
import { useSpring, animated, easings } from 'react-spring';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import cx from 'classnames';

import { IFullBleedHeroFields } from '@/types/contentful';

import { getImageDataFromOptions } from '../../utils/contentful';
import { usePageDataContext } from '../../context/PageData';
import { TextWithOptions } from '../Text/TextWithOptions';

import CtaWidget from '../CtaWidget';

import PrivacyPolicyDisclaimer from 'src/components/PrivacyPolicyDisclaimer';
import GlImage from '../Image';
import AdditionalContent from '../AdditionalContent';
import { ModalRevealCTA } from '../ModalRevealCTA';
import { SocialShare } from '../LearningCenter/Components/ArticleHeader/SocialShare';

export function FullBleedHero({
  headingText,
  headingTextOptions,
  desktopBackgroundImage,
  desktopBackgroundImageOptions,
  mobileBackgroundImage,
  mobileBackgroundImageOptions,
  privacyPolicyDisclaimer,
  ctaWidget,
  additionalContent,
  returningUserHeadingText,
  shareIcons,
  animationType = 'opacity',
  typewriterHeaders
}: IFullBleedHeroFields) {
  const {
    cookies: { utm_source = '' } = {},
    query: { gl_content, g_adgroupid } = {},
    isReturning
  } = usePageDataContext();

  // Determine if typewriter animation should be used
  const isTypewriter =
    animationType === 'typewriter' &&
    typewriterHeaders &&
    typewriterHeaders.length > 0;

  // Animation for headingText (only if animationType is 'opacity')
  const headingProps = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: animationType !== 'opacity' ? 0 : 400,
    config: {
      duration: 3000,
      easing: easings.easeOutExpo
    },
    immediate: animationType !== 'opacity' // Disable animation if typewriter is selected
  });

  // Animation for CTA and Privacy Policy (this will always use useSpring)
  const ctaProps = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: animationType !== 'opacity' ? 0 : 700,
    config: {
      duration: 3000,
      easing: easings.easeOutExpo
    },
    immediate: animationType !== 'opacity'
  });

  // Use useTypewriter unconditionally
  const [text] = useTypewriter({
    words: isTypewriter
      ? typewriterHeaders.map((header) => header + '.')
      : [''],
    loop: isTypewriter ? 0 : false,
    typeSpeed: isTypewriter ? 100 : 0,
    deleteSpeed: isTypewriter ? 100 : 0,
    delaySpeed: isTypewriter ? 2000 : 0
  });

  // Determine the heading content
  const headingContent = isTypewriter ? (
    <h1 className="mb-4 max-w-[375px] text-center text-5xl font-bold !leading-[120%] text-white mobile:text-left mobile:font-bold tablet:mb-6 tablet:max-w-[680px] tablet:text-6xl">
      {headingText} <span>{text}</span>
      <Cursor />
    </h1>
  ) : (
    <animated.h1
      style={headingProps}
      className="mb-4 max-w-[350px] text-center text-5xl font-bold !leading-[120%] text-white mobile:text-left mobile:font-bold tablet:mb-6 tablet:max-w-[644px] tablet:text-6xl"
    >
      <TextWithOptions
        data-testid="fullbleed-hero-headline"
        keyword={gl_content}
        channel={utm_source}
        adGroupId={g_adgroupid}
        fallback={
          (isReturning
            ? returningUserHeadingText ?? headingText
            : headingText) || ''
        }
        options={headingTextOptions}
        module="FullBleed Hero heading"
      />
    </animated.h1>
  );

  return (
    <div
      data-blockid="full-bleed-hero"
      data-testid="full-bleed-hero"
      data-e2e="hero"
      className="mb-16"
    >
      <div className="relative grid w-full">
        <div
          className={cx(
            'hidden aspect-[25/15] min-h-[512px] w-full max-w-[100vw] tablet:flex',
            {
              'max-h-[811px]': !additionalContent,
              'max-h-[750px]': additionalContent
            }
          )}
          style={{ gridArea: '1/1' }}
        >
          <GlImage
            {...getImageDataFromOptions(
              gl_content,
              utm_source,
              g_adgroupid,
              desktopBackgroundImage as Asset,
              desktopBackgroundImageOptions
            )}
            className="object-top"
            loading="eager"
            heroImage={true}
            layout="fill"
            objectFit="cover"
            width={undefined}
            height={undefined}
            alt="desktop-background-image"
          />
        </div>
        <div
          className="block aspect-[8/10] min-h-[400px] w-full max-w-[100vw] tablet:hidden"
          style={{ gridArea: '1/1' }}
        >
          <GlImage
            {...getImageDataFromOptions(
              gl_content,
              utm_source,
              g_adgroupid,
              mobileBackgroundImage as Asset,
              mobileBackgroundImageOptions
            )}
            loading="eager"
            heroImage={true}
            layout="fill"
            objectFit="cover"
            alt="mobile-background-image"
          />
        </div>
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid'
          }}
        >
          <div className="block-container block h-full !justify-normal tablet:flex tablet:flex-col">
            <div className="mt-auto flex h-full w-full px-5 pb-5 align-bottom tablet:px-11 tablet:pb-11 desktop:px-15 desktop:pb-15">
              <div className="self-end">
                {headingContent}
                <animated.div
                  style={animationType === 'opacity' ? ctaProps : {}}
                >
                  {ctaWidget &&
                    ctaWidget.sys.contentType.sys.id ===
                      'registrationEntrypoint' && (
                      <div className="max-w-[350px] desktop:mb-0">
                        <CtaWidget
                          width={'100%'}
                          {...ctaWidget?.fields}
                          sysId={ctaWidget?.sys?.contentType?.sys?.id}
                          id="fullwidth-hero-cta"
                          module="Hero"
                          height={56}
                          isMobile={false}
                          responsive
                        />
                      </div>
                    )}
                  {ctaWidget &&
                    ctaWidget.sys.contentType.sys.id === 'modalRevealCta' && (
                      <div className="max-w-[350px] desktop:mb-0">
                        <ModalRevealCTA {...(ctaWidget.fields as any)} />
                      </div>
                    )}
                  {privacyPolicyDisclaimer && (
                    <div className="mt-2 max-w-[350px] tablet:max-w-[467px] desktop:max-w-[435px]">
                      <PrivacyPolicyDisclaimer
                        privacyPolicyDisclaimer={privacyPolicyDisclaimer}
                        displayPrivacyPolicyIcon={false}
                        color={'white'}
                        noMargin
                      />
                    </div>
                  )}
                  {shareIcons && (
                    <div className="mt-5">
                      <SocialShare inline hideShareCopy socialOptions={'all'} />
                    </div>
                  )}
                </animated.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdditionalContent additionalContent={additionalContent} />
    </div>
  );
}
